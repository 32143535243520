#cookie-notice{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: $base-spacing/2;
  text-align: center;
  z-index: 10;
  a{
    color:$color-pink;
    padding: $base-spacing/4 $base-spacing/2;
  }
}