.two-column {
  .page-constraint {

    .two-column-inner {
      display: flex;
      flex-wrap: wrap;

      .two-column-left {
        flex: 0 0 50%;
        padding-right: $base-spacing/2;

        @include small {
          flex: 0 0 100%;
          padding-right: 0;
        }
      }

      .two-column-right {
        flex: 0 0 50%;
        padding-left: $base-spacing/2;

        @include small {
          flex: 0 0 100%;
          padding-left: 0;
        }
      }
    }
  }
}