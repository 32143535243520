.full-page-header-container {
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow: hidden;
  background-color: #ccc;

  .full-page-header-bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .full-page-header-question-mark{
    position: absolute;
    width: 150%;
    height: 150%;
    top: -50%;
    left: -50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    svg{
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
  }

  .page-constraint {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;

    .full-page-header-question-mark {
      position: absolute;
      left: -60%;
      top: -50%;
      width: 150%;
      height: 150%;
      background-position-x: 180%;
      background-position-y: 150%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url(../../templates/svg/question-mark.svg);
      opacity: .5;
      transform: scaleX(-1);
    }
    .full-page-header-main-content{
      text-transform: uppercase;
      font-size: 54px;
      font-weight: 600;
      color: $color-white;
      width: 55%;
      display: grid;
      align-content: center;
      position: relative;
      top: 30px;
      @include small{
        top: 0;
        width: 100%;
        display: none;
      }
      svg{
        position: relative;
        top: -60px;
        width: 100%;
        height: auto;
        @include small{
          top: 0;
        }
      }
    }
  }
}