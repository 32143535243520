.icon-bullets-container{
  position: relative;
  z-index: 1;
  overflow: hidden;

  .page-constraint{
    position: relative;
    z-index: 2;
    .icon-bullets-heading{

    }
    .icon-bullets-content{
      font-weight: 600;
      padding: $base-spacing 0;
      p{
        font-weight: 600;
      }
    }
    .bullets-container{
      .bullet-container{

        &:nth-child(2n-1) {
          .bullet-inner{
            .bullet-icon{
              img{

              }
              svg{
                stroke: $color-blue !important;
                fill: $color-blue;
                circle{
                  fill: transparent;
                  stroke: $color-blue !important;
                }
                path{
                  stroke: $color-blue !important;
                }
                polygon, rect{
                  fill: $color-blue !important;
                }
              }
            }
            .bullet-title{
              color: $color-blue;
            }
            .bullet-content{

            }
          }
        }
        &:nth-child(2n) {
          .bullet-inner{
            .bullet-icon{
              img{

              }
              svg{
                stroke: $color-pink !important;
                fill: $color-pink;
                circle{
                  fill: transparent;
                  stroke: $color-pink !important;
                }
                path{
                  fill: $color-pink !important;
                  stroke: $color-pink !important;
                }
                polygon, rect{
                  fill: $color-pink !important;
                }
              }
            }
            .bullet-title{
              color: $color-pink;
            }
            .bullet-content{
            }
          }
        }

        .bullet-inner{
          display: grid;
          grid-template-areas: "icon title"
                                "icon content";
          grid-column-gap: $base-spacing/2;
          grid-template-columns: 42px auto;
          .bullet-icon{
            grid-area: icon;
            width: 46px;
            img{

            }
            svg{
              width: 100%;
              height: auto;
            }
          }
          .bullet-title{
            font-weight: 700;
            padding-top: 10px;
            grid-area: title;
            .mobile-only-expand-icon{
              display: none;
              @include small{
                @media (hover: none) {
                  display: inline-block;
                }
              }
            }
          }
          .bullet-content{
            grid-area: content;
            p{
              @include small{
                @media (hover: none) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}