.standard-content-container{
  position: relative;
  z-index: 1;
  overflow: hidden;

  &.theme-pink-bg,
  &.theme-pink-bg-image{
    background-color: $color-pink;
    color: $color-white;
    *{
      color: $color-white;
    }
  }

  &.theme-blue-bg,
  &.theme-blue-bg-image{
    background-color: $color-blue;
    color: $color-white;
    *{
      color: $color-white;
    }
    ul{
      margin: $base-spacing/2;
      li{
        margin-bottom: 0;
        &:before{
          color: $color-white;
        }
      }
    }
  }

  .standard-content-bg-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.1;
  }

  .page-constraint{
    position: relative;
    z-index: 2;
    .standard-content-heading{
      margin-bottom: $base-spacing/2;
    }
    .standard-content-sub-heading{

    }
  }
}