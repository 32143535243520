


.video-page-container{
  .video-container{
    background-color: #fff;
    height: 500px;
    @include small{
      height: 300px;
    }
    @include xsmall{
      height: 200px;
    }
    width: auto;
    margin: auto;
    max-height: 90%;
    @include small{
      min-height: 300px;
    }
    @include xsmall{
      min-height: 200px;
    }
    position: relative;
    video{
      display: block;
      margin: auto;
      height: 500px;
      @include small{
        height: 300px;
      }
      @include xsmall{
        height: 200px;
      }
      width: 100%;
    }
    .page-constraint{
      position: relative;
    }
  }
  .video-content-container{
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 50% 50%;
    @include small{
      grid-template-areas: "left"
                          "right";
      grid-template-columns: 100%;
    }
    .video-content-container--left{
      grid-area: left;
      padding: $base-spacing $base-spacing $base-spacing 0;
      @include small{
        padding:0;
      }
      h1,h2{
        margin-bottom: $base-spacing/2;
      }
    }
    .video-content-container--right{
      position: relative;
      grid-area: right;
      padding: $base-spacing 0 $base-spacing $base-spacing;
      @include small{
        padding:0;
      }
      .ajax-sending-overlay{
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.8);
        .ajax-sending-overlay-inner{
          width: 80px;
          height: 80px;
          position: relative;
          top: 50%;
          left: 50%;
          margin-top: -40px;
          margin-left: -40px;
        }
      }
      textarea{
        background-color: $color-light-grey;
        border: 1px solid $color-light-grey;
      }
      .video-content-container--right-question-number{
        margin-bottom: $base-spacing;
        font-size: 15px;
      }
      .answer-type{
        font-size: 14px;
      }
      .successful-submission-content{
        display: none;
      }
    }
  }
}

.answers-section{
  .button-video-answer{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    display: block;
    &.type-single{
      border-color: $color-blue;
      color: $color-blue;
    }
    &.type-multi{
      border-color: $color-blue;
      color: $color-blue;
    }
    &.active{
      background-color: $color-blue;
      color: $color-white;
    }
  }
}


.next-video-container{
  display: none;
  position: absolute;
  bottom: 40px;
  color: #fff;
  background-color: rgba(0,0,0,.8);
  padding: 15px 25px;
  line-height: 1.3;
  font-size: 28px;
  max-width: 100%;
  a,a:visited{
    color: $color-white;
    &:hover, &:active{
      color: $color-white;
      text-decoration: underline;
    }
  }
}