.news-header-container {
  background-color: $color-blue;
  .page-constraint {
    .news-header-title{
      h1,h2{
        color: $color-white;
      }
      margin-bottom: $base-spacing*2;
    }
    .news-block-header-container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include small{
        grid-template-columns: 1fr;
      }
      .news-block-header-highlight{
        position: relative;
        .news-block-header-highlight-image{
          position: relative;
          top: $base-spacing*2;
          background-color: #fff;
          padding-top: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: 100px;
          @include small{
            top: 0;
            margin-bottom: 0;
          }
        }
      }
      .news-block-header-list{
        background-color: $color-pink;
        padding: 0;
        margin-bottom: 0;
        height: fit-content;
        &:hover{
          a{
            color: rgba(255,255,255,0.5);
          }
        }
        a{
          display: block;
          color: rgba(255,255,255,1);
          padding: $base-spacing/2 $base-spacing;
          height: 120px;
          &:hover{
            color: rgba(255,255,255,1);
            .news-block-header-list-item{
              .news-block-header-list-item-content{
              }
              .news-block-header-list-item-icon{
                transform: scale(1.2);
                left: 10px;
                svg{
                }
              }
            }
          }
          .news-block-header-list-item{
            display: grid;
            grid-template-areas: "content icon";
            grid-template-columns: auto 46px;
            height: 100%;
            .news-block-header-list-item-content{
              grid-area: content;
              font-weight: 500;
              font-size: $font-size-body;
              align-self: center;
            }
            .news-block-header-list-item-icon{
              transition: transform 0.2s, left 0.2s;
              grid-area: icon;
              align-self: center;
              text-align: right;
              border: 1px solid $color-white;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              position: relative;
              left: 0;
              svg{
                position: relative;
                top: -4px;
                left: 1px;
                padding: 10px;
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}