.featured-articles {
  .article-grid-container {
    background-color: $color-white;
    color: $color-dark-grey;
    .page-constraint {
      .article-grid-main-heading{
        color: $color-blue;
        h1,h2{
          color: $color-blue;
        }
      }
      .article-grid-sub-heading {
        color: $color-dark-grey;
      }
      .articles-container {
        .article-block {
          .article-block-inner {
            a {
              color: $color-dark-grey;


              .article-title {
                color: $color-dark-grey;
              }

            }

            .article-tags {
              a {
                color: $color-aqua;
              }
            }
          }
        }
      }
    }
  }
}

.articles {
  .article-grid-container {
    background-color: $color-pink;
    color: $color-white;
    .page-constraint {
      .article-grid-main-heading{
        color: $color-white;
        h1,h2{
          color: $color-white;
        }
      }
      .article-grid-sub-heading {
        color: $color-white;
      }
      .articles-container {
        .article-block {
          .article-block-inner {
            a {
              color: $color-white;


              .article-title {
                color: $color-white;
              }

            }

            .article-tags {
              a {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}


.article-grid-container {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .page-constraint {
    position: relative;
    z-index: 2;

    .article-grid-main-heading {
      margin-bottom: $base-spacing;
      h1,h2 {
        span {
          display: inline-block;
          margin-right: $base-spacing/2;
          position: relative;
          top: 8px;

          img {
            display: inline-block;
            width: 36px;
            height: 36px;
          }

          svg {
            stroke: $color-blue !important;
            fill: $color-blue;

            circle {
              fill: transparent;
              stroke: $color-blue !important;
            }

            polygon, rect {
              fill: $color-blue !important;
            }
          }
        }
      }
    }

    .article-grid-sub-heading {
      font-size: $font-size-sub-header;
      font-weight: 700;
      margin-top: $base-spacing/2;
      margin-bottom: $base-spacing/2;
    }


    .articles-container {
      margin-left: -$base-spacing;
      margin-right: -$base-spacing;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;

      .article-block {
        flex: 0 0 33.333%;
        @include small{
          flex: 0 0 50%;
        }
        @include xsmall{
          flex: 0 0 100%;
        }
        .article-block-inner {
          padding: 0 $base-spacing $base-spacing $base-spacing;

          a {
            color: $color-dark-grey;

            &:hover {
              .article-block-thumbnail-container {
                .article-block-thumbnail-bg {
                  opacity: 1;
                  transform: scale(1.1);
                }
              }
            }

            position: relative;

            .article-block-thumbnail-container {
              overflow: hidden;
              background-color: #000;
              position: relative;

              .article-block-thumbnail-bg {
                opacity: 1;
                transition: opacity 0.2s, transform 0.2s;
                background-position: center;
                background-size: cover;
                width: 100%;
                padding-top: 56.25%;
                box-sizing: border-box;
                position: relative;
              }

            }

            .article-date {
              font-size: 16px;
            }

            .article-title {
              color: $color-dark-grey;
              font-weight: 600;
            }

            .article-excerpt {
              font-size: 14px;

              p {
                font-size: 16px;
              }
            }

          }

          .article-tags {
            margin-top: $base-spacing/4;
            font-size: 14px;

            a {
              font-weight: 800;
              text-transform: uppercase;
              color: $color-aqua;
            }
          }
        }
      }
    }
  }
}