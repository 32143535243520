.symptom-checker-page-container{
  .symptom-checker-answer-any-yes,
  .symptom-checker-answer-all-no{
    display: none;
    background-color: $color-light-grey;
    border: 2px solid $color-blue;
    padding: $base-spacing;
    img{
      width: 300px;
      float: right;
      position: relative;
      top: -60px;
      @include medium{
        top: 0;
      }
      @include small{
        width: 100%;
      }
    }
    a{
      span{
        display: inline-block;
        width: 16px;
        svg,img{
          position: relative;
          top: 5px;
          margin-right: 10px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.symptom-checker-container {
  padding: $base-spacing 0;

  .symptom-checker-question {
    padding: $base-spacing/2;
    &:nth-child(2n-1) {
      background-color: rgba($color-blue, 0.1);
    }
  }

  label {
    font-weight: 900;
    cursor: pointer;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #29ac1b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #29ac1b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}