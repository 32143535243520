.cta-container{
  position: relative;
  z-index: 1;
  .page-constraint{



    position: relative;
    z-index: 2;
    display: grid;
    grid-template-areas: "question-mark main-content"
                        "foot-content foot-content";
    grid-template-columns: 260px auto;



    @include small{
      grid-template-areas: "question-mark"
                        "main-content"
                        "foot-content";
      grid-template-columns: auto;
      text-align: center;
    }

    &.no-question-mark{
      grid-template-areas: "main-content"
                        "foot-content";
      grid-template-columns: auto;
      .cta-main-content,
      .cta-main-content p{
        padding-left: 0;
      }
      .cta-foot-content,
      .cta-foot-content p{
        padding-top: 0;
      }
    }
    .cta-question-mark{
      grid-area: question-mark;
      align-self: end;
      transition: transform 0.2s;
    }
    &:hover{
      .cta-question-mark{
        transform:  rotate(10deg) scale(1.1);
      }
    }
    .cta-main-content,
    .cta-main-content p{
      grid-area: main-content;
      align-self: center;
      font-family: $font-family-ctas;
      font-size: $font-size-cta;
      color: $color-dark-grey;
      line-height: 1.4;
      padding-left: $base-spacing/2;
      font-weight: 400;
      @include small{
        padding-left: 0;
      }
    }
    .cta-foot-content,
    .cta-foot-content p{
      grid-area: foot-content;
      font-family: $font-family-ctas;
      font-size: $font-size-cta;
      color: $color-dark-grey;
      line-height: 1.2;
      padding-top: $base-spacing;
    }
  }
  &.theme-plain{

  }
  &.theme-blue-bg,
  &.theme-blue-bg-image{
    background-color: $color-blue;
    .page-constraint{
      .cta-question-mark{
        .question-mark-block{
          .question-mark-text{
            color: $color-white;
          }
        }
      }
      .cta-main-content,
      .cta-main-content p{
        color: $color-white;
      }
      .cta-foot-content,
      .cta-foot-content p{
        color: $color-white;
      }
    }
  }
  &.theme-blue-bg-image{
    .cta-bg-image{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.1;
    }
  }
  &.right-question-mark{
    .page-constraint {
      display: grid;
      grid-template-areas: "main-content question-mark"
                        "foot-content foot-content";
      grid-template-columns: auto 260px;
      @include small{
        grid-template-areas: "question-mark"
                        "main-content"
                        "foot-content";
        grid-template-columns: auto;
      }
      .cta-main-content,
      .cta-main-content p{
        padding-left: 0;
        padding-right: $base-spacing/2;
        @include small{
          padding-right: 0;
        }
      }
      .cta-foot-content,
      .cta-foot-content p{
        padding-top: 0;
      }
    }
  }
}