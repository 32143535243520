.question-mark-block {
  position: relative;
  display: inline-block;

  .question-mark-image {

  }

  .question-mark-text {
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px 64px 60px 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: MetaPro,serif;
    font-size: 47px;
    font-weight: 700;
    line-height: 0.9;
    text-transform: uppercase;
    color: $color-black;
  }
}