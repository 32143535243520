.top-navigation {
  position: relative;
  z-index: 2;

  a {

    color: #000;
  }

  .top-container-cover-pink{
    background-color: $color-pink;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 600px;
  }

  .page-constraint {
    position: relative;
    display: grid;
    grid-template-areas: "logo right";
    grid-template-columns: auto 450px;
    @include medium {
      grid-template-areas: "logo"
                            "right";
      grid-template-columns: 100%;
      padding: 0;
    }

    .top-navigation-circle-container{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .top-navigation-circle-container-inner{
        width: 100%;
        padding-top: 100%;
        background-color: $color-pink;
        border: 30px solid $color-blue;
        border-radius: 50%;
        position: relative;
        top: -80%;
        left: 36%;
        @include medium{
          display: none;
        }
      }
    }

    .top-navigation-logo-container {
      grid-area: logo;
      position: relative;
      z-index: 2;
      svg{
        max-width: 100%;
      }
      @include medium{
        padding: $base-spacing/2 $base-spacing/2;
      }
    }

    .top-navigation-right-container {
      position: relative;
      z-index: 2;
      grid-area: right;

      display: grid;
      grid-template-areas: "cta-links cta-links"
                          "menu menu";
      grid-template-columns: 1fr auto;

      @include medium{
        border-top: 15px solid $color-blue;
        background-color: $color-pink;
        padding: $base-spacing/2 $base-spacing/2;
      }

      .top-navigation-ctas-container {
        grid-area: cta-links;
        align-self: center;
        justify-self: start;
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include small{
          display: block;

        }

        .top-navigation-cta {
          display: inline-block;
          margin-right: 56px;

          &:last-child {
            margin-right: 0;
          }

          a {
            &:hover {
              span {
                transform: rotate(10deg) scale(1.1);
              }
            }

            padding: 5px 0;
            @include medium {
              padding-left: 52px;
            }
            @include medium {
              padding-left: 42px;
            }
            display: inline-block;
            position: relative;
            font-size: $font-size-sub-header;
            text-transform: uppercase;
            font-family: $font-family-headings;
            color: $color-white;

            @include small{
              width: 100%;
              font-size: $font-size-sub-header-medium;
              margin: 10px 0;
            }

            span {
              transition: transform 0.2s;
              display: inline-block;
              position: absolute;
              top: 0;
              left: -52px;
              width: 42px;
              @include medium {
                top: 4px;
                left: 0;
              }
              @include small{
                width: 32px;
                left: 0;
              }

              svg {
                height: 100%;
                width: 100%;

                rect, polygon {
                  fill: $color-white !important;
                }

                circle {
                  stroke: $color-white !important;
                }
              }
            }

          }

          &.top-navigation-cta--symptom-checker {
          }

          &.top-navigation-cta--live-poll {

          }
        }
      }

      .top-navigation-menu-container {
        grid-area: menu;
        align-self: center;
        text-align: left;

        ul {
          margin: 0;

          li {
            &:before {
              content: none;
            }

            margin: 0;
          }
        }

        > ul {
          > li {
            display: inline-block;
            position: relative;
            margin-right: $base-spacing/2;

            &:last-of-type{
              float: right;
              margin-right: 0;
              position: relative;
              top:13px;
              padding-bottom: 10px;
              width: 32px;

              img{
                display: block;
              }
              > a {
                position: relative;
                z-index: 2;
                color: $color-white;
                padding: 0;
                margin-right: 0;
                padding-bottom: 20px;
                left: -5px;
                top: -5px;

                span {
                  display: none;
                }
                @include small{
                  margin: 10px 0;
                }
              }

              &:hover {
                > ul {
                  display: block;
                  height: initial;
                  opacity: 1;
                  padding: 5px;
                }
              }

              > ul {
                position: absolute;
                top: -26px;
                left: -10px;
                height: 0;
                overflow: hidden;
                opacity: 0;
                min-width: 0;
                background-color: rgba($color-white, 0.8);
                transition: opacity 0.2s;
                margin-top: 15px;

                > li {
                  padding: 0;
                  margin-bottom: 5px;

                  > a {
                    color: #036;
                    padding: 0;
                  }
                }
              }
            }

            @include xsmall{
              width: 100%;
            }
            > a {
              color: $color-white;
              padding: 5px 0;
              margin-right: 15px;
              padding-bottom: 20px;
              @include xsmall{
                width: 100%;
              }

              span {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-left: 5px;
                position: relative;
                top: 3px;

                svg {
                  transform: rotate(90deg);
                  width: 100%;
                  height: 100%;
                  @include xsmall{
                    display: none;
                  }
                }
              }
            }

            &:hover {
              > ul {
                display: block;
                height: initial;
                opacity: 1;
                padding: 5px;
                @include xsmall{
                  padding: 0;
                }
              }
            }

            > ul {
              position: absolute;
              height: 0;
              overflow: hidden;
              opacity: 0;
              min-width: 250px;
              background-color: rgba($color-white, 0.8);
              transition: opacity 0.2s;
              margin-top: 15px;

              @include xsmall{
                width: 100%;
                opacity: 1;
                position: relative;
                height: initial;
                background-color: transparent;
                margin-top: 5px;
                margin-bottom: -5px;
              }

              > li {
                padding: 5px;
                @include xsmall{
                  padding: 5px 0;
                }

                > a {
                  color: #036;
                  @include xsmall{
                    color: $color-white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}