.triple-pod-container {
  background-color: $color-very-light-grey;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .st0{
    fill:$color-white;
  }
  .triple-pod-bg-question-mark {
    position: absolute;
    left: -60%;
    top: -50%;
    width: 150%;
    height: 150%;
    background-position-x: 180%;
    background-position-y: 150%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(../../templates/svg/question-mark.svg);
    opacity: .5;
    transform: scaleX(-1);
  }

  .triple-pod-heading {

  }

  .page-constraint {
    position: relative;
    z-index: 2;

    .pods-container {
      display: flex;
      flex-wrap: wrap;
      justify-items: center;
      text-align: center;
      margin-left: -$base-spacing/2;
      margin-right: -$base-spacing/2;
      justify-content: center;


      &:hover {
        .pod {
          opacity: 0.8;

          &:hover {
            opacity: 1;

            .pod-inner {
              .pod-icon {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      .pod {
        flex: 0 0 33.333%;

        @include medium{
          flex: 0 0 50%;
          margin-bottom: $base-spacing;
        }
        @include small{
          flex: 0 0 100%;
          margin-bottom: $base-spacing;
        }

        color: $color-white;
        transition: opacity 0.2s;

        .pod-inner {
          background-color: $color-pink;
          padding: $base-spacing;
          margin: $base-spacing/2;
          margin-bottom: -$base-spacing;
          height: 100%;

          .pod-icon {
            transition: transform 0.2s;
            max-width: 70%;
            margin: auto;

            svg {
              max-width: 70%;
              fill: #fff;
              stroke: #fff;

              path, circle, polygon, line, ellipse {
                stroke: #fff;
              }
            }
          }

          .pod-title {
            font-size: $font-size-sub-header;
            padding: $base-spacing 0;
            line-height: 1.3;
            font-weight: 600;
          }

          .pod-content {
            p{
              line-height: 1.3;
            }
          }
        }
      }
    }
  }
}