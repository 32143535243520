// Moves the body down 32px when the wp admin toolbar is shown
body.customize-support {
  padding-top: 32px;
}

*, *:after, *:before {
  box-sizing: border-box;
  font-family: $font-family-body;
  margin: 0;
  padding: 0;
  outline: none;
  font-weight: 300;
}

html, body {
  min-height: 100vh;
  font-size: $font-size-body;
  background-color: $color-white;
  color: $color-dark-grey;
  width: 100%;
  overflow-x: hidden;
}

blockquote {
  font-size: 1.5rem;
  margin: 52px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 700;
  font-style: italic;
  @include xsmall {
    margin: $base-spacing 0 0 0;
  }
}

ol {
  margin: $base-spacing $base-spacing $base-spacing*2 $base-spacing;
}

ul {
  margin: $base-spacing $base-spacing $base-spacing*2 $base-spacing;
  list-style: none;

  li {
    &:before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;

      color: $color-blue;
      margin-left: -$base-spacing/2;
      margin-top: 5px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f111";
      font-size: 6px;
      position: relative;
      padding-right: 10px;
    }

    margin-bottom: $base-spacing/2;
  }

  ul {
    color: $color-aqua;

    li {
      &:before {
        color: $color-aqua;
      }
    }
  }
}

p {
  margin-bottom: $base-spacing/2;
  font-size: $font-size-body;
}

.text-aqua {
  color: $color-aqua;
}

.page-constraint {
  max-width: 1200px;
  margin: auto;
  padding: $base-spacing;

  &.no-vert-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  a, p a {
    color: $color-very-dark-grey;

    &:hover {
      color: $color-very-dark-grey;
      text-decoration: underline;
    }
  }

  @include small {
    padding: $base-spacing/2;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: $font-size-header;
  margin: 0;
  color: $color-blue;
  font-family: $font-family-headings;
  font-weight: 300;

  * {
    font-family: $font-family-headings;
  }

  &.subheading {
    font-size: $font-size-sub-header;
    padding: $base-spacing/2 0;
  }

  @include medium {
    font-size: $font-size-header-medium;
  }
  @include small {
    font-size: $font-size-header-small;
  }
}

h3, h4, h5, h6 {
  font-size: 26px;
  margin-bottom: $base-spacing/3*2;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;

  &.circled-blue {
    //border: 4px solid $color-blue;
  }
}

hr {
  margin: ($base-spacing * 2) 0 $base-spacing 0;
}

a {
  text-decoration: none;
  transition: color $animation-fade;
  color: $color-white;

  &:visited {
    color: $color-white;
  }

  &:hover,
  &:active {
    color: darken($color-white, 20%);
  }

  &.dark-grey {
    color: $color-dark-grey;
    border-bottom: 2px solid;
    border-color: transparent;
    transition: border-color $animation-fade;

    &:visited {
      color: $color-dark-grey;
    }

    &:hover,
    &:active {
      color: $color-dark-grey;
      border-color: $color-dark-grey;
    }
  }

  &.orange {
    color: $color-orange;

    &:visited {
      color: $color-orange;
    }

    &:hover,
    &:active {
      color: $color-orange-dark;
    }
  }

  &.turquoise {
    color: $color-turquoise;

    &:visited {
      color: $color-turquoise;
    }

    &:hover,
    &:active {
      color: $color-turquoise-dark;
    }
  }
}

sub {
  font-size: 11px;
  font-weight: 400;
  top: -$base-spacing/2;
}

table {
  width: 100%;
  min-width: 540px;
  overflow-x: auto;
}

.standard-link {
  text-transform: uppercase;
  color: $color-dark-grey;
  font-size: 12px;

  &:visited {
    text-transform: uppercase;
    color: $color-dark-grey;
  }

  &:hover {
    text-decoration: underline;
    color: $color-dark-grey;
  }
}

.button {
  font-family: 'Roboto', sans-serif;
  font-size: $font-size-button;
  display: inline-block;
  margin: $base-spacing/2 0;
  padding: $base-spacing/3 $base-spacing/2;
  color: #333;
  border: 3px solid #333;
  text-transform: uppercase;
  transition: color $animation-fade, border $animation-fade, background-color $animation-fade;
  background-color: transparent;
  border-radius: 100px;
  font-weight: bold;

  &.button-block {
    width: 100%;
  }

  &.button-white {
    color: $color-dark-grey;
    border-color: $color-white;

    &:visited {
      color: $color-white;
      border-color: $color-white;
    }
  }

  &.button-color-white-bg-blue {
    color: $color-white;
    border-color: $color-blue;
    background-color: $color-blue;

    &:visited {
      color: $color-white;
      border-color: $color-blue;
      background-color: $color-blue;
    }
  }

  &.button-color-white-bg-aqua {
    color: $color-white;
    border-color: $color-aqua;
    background-color: $color-aqua;

    &:visited {
      color: $color-white;
      border-color: $color-aqua;
      background-color: $color-aqua;
    }
  }

  &.button-border-aqua-color-blue-bg-transparent {
    color: $color-blue;
    background-color: transparent;
    border-color: $color-aqua;

    &:visited {
      color: $color-aqua;
      background-color: transparent;
      border-color: $color-blue;
    }
  }

  &.button-border-aqua-color-white-bg-transparent {
    color: $color-white;
    background-color: transparent;
    border-color: $color-aqua;

    &:visited {
      color: $color-white;
      background-color: transparent;
      border-color: $color-blue;
    }
  }

  &.button-color-dark-grey-bg-white {
    color: $color-dark-grey;
    background-color: $color-white;
    border-color: $color-white;

    &:visited {
      color: $color-dark-grey;
      background-color: $color-white;
      border-color: $color-white;
    }
  }

  &.button-white-dark-grey-border {
    color: $color-dark-grey;
    border-color: $color-dark-grey;

    &:visited {
      color: $color-dark-grey;
      border-color: $color-dark-grey;
    }
  }

  &.button-dark-grey {
    color: $color-white;
    border-color: $color-dark-grey;
    background-color: $color-dark-grey;

    &:visited {
      color: $color-white;
      border-color: $color-dark-grey;
    }
  }

  &.button-very-dark-grey {
    color: $color-white;
    border-color: $color-very-dark-grey;
    background-color: $color-very-dark-grey;

    &:visited {
      color: $color-white;
      border-color: $color-very-dark-grey;
    }
  }


  // Hovers separate so they can be mixed and matched with the non-hover states
  &.button-hover-color-light-grey {
    &:hover,
    &:active {
      color: $color-light-grey;
    }
  }

  &.button-hover-color-dark-grey-bg-white {
    &:hover,
    &:active {
      color: $color-dark-grey;
      background-color: $color-white;
      border-color: $color-white;
    }
  }

  &.button-hover-color-white-bg-light-grey {
    &:hover,
    &:active {
      color: $color-white;
      background-color: $color-light-grey;
      border-color: $color-light-grey;
    }
  }

  &.button-hover-color-white-bg-dark-grey {
    &:hover,
    &:active {
      color: $color-white;
      background-color: $color-dark-grey;
      border-color: $color-dark-grey;
    }
  }

  &.button-hover-bg-very-dark {
    &:hover,
    &:active {
      color: $color-white;
      background-color: $color-very-dark-grey;
      border-color: $color-very-dark-grey;
    }
  }

  &.button-hover-bg-black {
    &:hover,
    &:active {
      color: $color-white;
      background-color: $color-black;
      border-color: $color-black;
    }
  }

  &.button-hover-color-aqua {
    &:hover,
    &:active {
      color: $color-aqua;
    }
  }
}

.screen-reader-response {
  ul {
    margin: 0;
  }
}

input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
textarea {
  width: 100%;
  background-color: $color-white;
  padding: ($base-spacing/4) ($base-spacing/2);
  border: 1px solid $color-dark-grey;
}

.text-orange {
  color: $color-orange;
}

.text-turquoise {
  color: $color-turquoise;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $color-pink;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.single-post-container {
  position: relative;

  h1, h2 {
    margin-bottom: $base-spacing/2;
  }

  .image-column {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include small {
      height: 200px;
    }
    @include medium {
      height: 300px;
    }

    img,
    svg {
      border-radius: 4px;
      width: 100%;
      height: auto;
    }
  }
}

.to-top-container{
  position: fixed;
  bottom: $base-spacing*2;
  right: $base-spacing*2;
  z-index: 10000;
  display: none;
  a{
    display: block;
    padding: $base-spacing/2;
    background-color: $color-pink;
    svg{
      width: 32px;
      height: 32px;
      transform: rotate(-90deg);
      transform-origin: center;
      position: relative;
      top: 6px;
      path{
        color: $color-white;
      }
    }
  }
}

.two-column-right-inner{
  a,a:visited{
    color: $color-very-dark-grey;
    &:hover{
      color: $color-very-dark-grey;
    }
  }
}

.top-navigation .page-constraint .top-navigation-right-container .top-navigation-menu-container>ul>li:last-of-type{
  display: none;
}