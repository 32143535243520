.video-grid-container{
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #ddd;
  .page-constraint{
    position: relative;
    z-index: 2;

    .video-grid-main-heading{
      h1,h2{
        span{
          display: inline-block;
          margin-right: $base-spacing/2;
          position: relative;
          top: 13px;
          width: 54px;
          img{
            display: inline-block;
            width: 54px;
            height: 54px;
          }
          svg{
            width: 100%;
            height: auto;
            stroke: $color-blue !important;
            fill: $color-blue;
            circle{
              fill: transparent;
              stroke: $color-blue !important;
            }
            polygon, rect, path{
              fill: $color-blue !important;
            }
          }
        }
      }
    }
    .video-grid-sub-heading{
      font-size: $font-size-sub-header;
      font-weight: 700;
      margin-top: $base-spacing/2;
      margin-bottom: $base-spacing/2;
    }


    .videos-container{
      margin-left: -$base-spacing;
      margin-right: -$base-spacing;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .video-block{
        flex: 0 0 33.333%;
        @include small{
          flex: 0 0 50%;
        }
        @include xsmall{
          flex: 0 0 100%;
        }
        .video-block-inner{
          padding: 0 $base-spacing $base-spacing $base-spacing;
          a{
            &:hover {
              .video-block-thumbnail-container {
                .video-block-thumbnail-bg {
                  opacity: 1;
                  transform: scale(1.1);
                }
                .video-block-thumbnail-question-mark-container{
                  transform: scale(0.9);
                }
                .video-block-thumbnail-play-icon-container{
                  transform: scale(1.2);
                }
              }
            }
            position: relative;
            display: block;
            .video-block-thumbnail-container{
              overflow: hidden;
              background-color: #000;
              position: relative;
              .video-block-thumbnail-bg{
                opacity: 1;
                transition: opacity 0.2s, transform 0.2s;
                background-position: center;
                background-size: cover;
                width: 100%;
                padding-top: 56.25%;
                box-sizing: border-box;
                position: relative;
              }
              .video-block-thumbnail-question-mark-container{
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 0.2s;
                svg{
                  position: relative;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 60%;
                  height: 60%;
                }
              }
              .video-block-thumbnail-play-icon-container{
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 0.2s;
                svg{
                  position: relative;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 20%;
                  height: 20%;
                  margin-left: 4px;
                }
              }
            }
            .video-title{
              color: $color-dark-grey;
            }
          }
        }
      }
    }
  }
}


// For the included block on the video article page
.video-page-other-videos-section{
  .video-grid-container{
    .page-constraint{

      .videos-container{
        margin-left: -$base-spacing;
        margin-right: -$base-spacing;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .video-block{
          flex: 0 0 25%;
          @include small{
            flex: 0 0 50%;
          }
          @include xsmall{
            flex: 0 0 100%;
          }
          .video-block-inner{
            padding: 0 $base-spacing $base-spacing $base-spacing;
            a{
              &:hover {
                .video-block-thumbnail-container {
                  .video-block-thumbnail-bg {
                    opacity: 1;
                    transform: scale(1.1);
                  }
                  .video-block-thumbnail-question-mark-container{
                    transform: scale(0.9);
                  }
                  .video-block-thumbnail-play-icon-container{
                    transform: scale(1.2);
                  }
                }
              }
              position: relative;
              display: block;
              .video-block-thumbnail-container{
                overflow: hidden;
                background-color: #000;
                position: relative;
                .video-block-thumbnail-bg{
                  opacity: 1;
                  transition: opacity 0.2s, transform 0.2s;
                  background-position: center;
                  background-size: cover;
                  width: 100%;
                  padding-top: 56.25%;
                  box-sizing: border-box;
                  position: relative;
                }
                .video-block-thumbnail-question-mark-container{
                  display: none;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  transition: transform 0.2s;
                  svg{
                    position: relative;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 60%;
                    height: 60%;
                  }
                }
                .video-block-thumbnail-play-icon-container{
                  display: none;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  transition: transform 0.2s;
                  svg{
                    position: relative;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 20%;
                    height: 20%;
                    margin-left: 4px;
                  }
                }
              }
              .video-title{
                color: $color-dark-grey;
              }
            }
          }
        }
      }
    }
  }
}