.symptom-checker-container {
  position: relative;
  .symptom-checker-bg-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    @include small{
      display: none;
    }
  }
  .symptom-checker-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(to right,#fff,#fff 50%,rgba(255,255,255,0) 75%);
    @include small{
      display: none;
    }
  }
  .page-constraint {
    position: relative;
    z-index: 2;
    padding-bottom: $base-spacing * 4;
    @include small{
      padding-bottom: $base-spacing;
    }
    .symptom-checker-inner {

      width: 60%;

      @include small{
        width: 100%;
      }

      .symptom-checker-heading {
        padding-bottom: $base-spacing;
      }

      .symptom-checker-subheading {
        font-size: $font-size-sub-header;
        font-weight: 800;
        padding-bottom: $base-spacing;
      }

      .symptom-checker-main-content {
        font-weight: 800;

        p {
          font-weight: 800;
        }
      }

      .symptom-checker-button {
        a {
          display: block;
          color: $color-blue;
          text-transform: uppercase;
          font-size: $font-size-sub-header;
          font-weight: 800;
          img,svg{
            position: relative;
            top: 15px;
            width: 54px;
            height: auto;
            margin-right: 5px;
            path{
              fill:$color-blue;
            }
          }
        }
      }
    }
  }
}