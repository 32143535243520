/**
  SPACING
 */
$base-spacing: 32px;

/**
  COLORS
 */
$color-white:#fff;
$color-black:#000;
$color-blue: #014289;
$color-aqua: #00A1D4;
$color-pink: #E83884;

$color-very-light-grey:#e9e9e9;
$color-light-grey:#ddd;
$color-dark-grey:#555;
$color-very-dark-grey:#222;

$color-orange-light: #ffbf71;
$color-orange: #f49e31;
$color-orange-dark: darken($color-orange, 20%);

$color-turquoise: #3dc0bb;
$color-turquoise-dark: darken($color-turquoise, 20%);

/**
  ANIMATIONS
 */
$animation-fade:0.2s;


/**
  FONTS
 */
$font-header: '';
$font-size-header: 42px;
$font-size-header-medium: 36px;
$font-size-header-small: 28px;
$font-sub-header: '';
$font-size-sub-header:  28px;
$font-size-sub-header-medium:  19px;
$font-size-sub-header-small:  17px;
$font-body: '';
$font-size-body: 22px;
$font-size-body-medium: 15px;
$font-size-body-small: 14px;
$font-button: '';
$font-size-button: 1rem;
$font-size-button-medium: 0.9rem;
$font-size-button-small: 0.7rem;
$font-size-cta: 1.3rem;

$font-family-question-mark: 'MetaPro', serif;
$font-family-headings: 'MetaPro-Medi', serif;
$font-family-ctas: 'MetaPro', serif;
$font-family-body: 'MetaPro', serif;

/**
  SIZES
 */
$size-xsmall: 480px;
$size-small: 768px;
$size-medium: 1024px;
$size-large: 1400px;
$size-extralarge: 1980px;