.footer-container{
  background-color: $color-white;
  border-top: 15px solid $color-pink;
  .page-constraint{
    padding-top: 0;
    &.top-container{color: rgba($color-pink, 0.8);
      display: grid;
      grid-template-areas: "left right";
      grid-template-columns: 1fr 3fr;
      @include small{
        grid-template-areas: "left"
                          "right";
        grid-template-columns: 1fr;
      }
      .footer-left-container{
        grid-area: left;
        svg{
          width: 300px;
          max-width: 100%;
        }
        @include small{
          text-align: center;
        }
      }
      .footer-right-container{
        grid-area: right;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include small{
          text-align: center;
          justify-content: center;
        }
        @include small{
          text-align: center;
        }

        ul{
          margin: 0;
          padding: 0;
          li{
            display: inline-block;
            &:before{
              content: none;
            }
            margin: 0;
            padding: 0;
            a{
              color: rgba($color-pink, 0.8);
              margin: 0;
              padding: 0;
              margin-left: $base-spacing/2;
              &:hover{
                color: rgba($color-pink, 1);
              }
            }
          }
        }
      }
    }

    .footer-bottom-container{
      width: 100%;
      display: grid;
      grid-template-areas: "bleft bcenter bright";
      grid-template-columns: 33.333%;
      @include small{
        grid-template-areas: "bleft"
                              "bcenter"
                              "bright";
        grid-template-columns: 100%;
      }
      .footer-bottom-container-left{
        align-self: center;
        grid-area: bleft;
        text-align: left;
        @include small{
          text-align: center;
        }
        .sponsored-by{
          display: inline-block;
          padding-right: 10px;
        }
        .gr-logo{
          width: 200px;
          max-height: 40px;
          display: inline-block;
          vertical-align: sub;

          svg{
            width: 100%;
            height: auto;
          }
        }
      }
      .footer-bottom-container-center{
        grid-area: bcenter;
        text-align: center;
      }
      .footer-bottom-container-right{
        grid-area: bright;
        text-align: right;
        @include small{
          text-align: center;
        }
      }
    }
  }
}