.modal-container{
  text-align: center;
  padding: $base-spacing*2 $base-spacing;
  max-width: 90%;
  max-height: 90%;
  width: 800px;
  background-color: $color-white;
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  h1,h2{
    font-weight: bold;
  }
  a{
    margin:$base-spacing/2;
  }
}
.modal-underlay{
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.8);
}