.about-uf-container {
  .page-constraint {
    .about-uf-title {

    }

    .about-uf-top-image {
      width: 100%;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .about-uf-subtitle a,
    .about-uf-subtitle {
      color: $color-blue;
      font-weight: 600;
      padding-bottom: $base-spacing;
    }

    .about-uf-content {
      display: none;
      ol{
        li{
          margin-bottom: $base-spacing/2;
        }
      }
      .about-uf-content-image {
        width: 40%;
        float: right;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .about-uf-highlight {
      margin-top: $base-spacing*2;
      color: $color-pink;
      font-weight: 800;

      p {
        font-weight: 800;
      }
    }
  }
}