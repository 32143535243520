
@font-face {
  font-family: 'MetaPro';
  src: url('../fonts/MetaPro-Bold.eot');
  src: local('../fonts/MetaPro-Bold'),
  url('../fonts/MetaPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MetaPro-Bold.woff2') format('woff2'),
  url('../fonts/MetaPro-Bold.woff') format('woff'),
  url('../fonts/MetaPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro-Medi';
  src: url('../fonts/MetaPro-Medi.eot');
  src: local('../fonts/MetaPro-Medi'),
  url('../fonts/MetaPro-Medi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MetaPro-Medi.woff2') format('woff2'),
  url('../fonts/MetaPro-Medi.woff') format('woff'),
  url('../fonts/MetaPro-Medi.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'MetaPro';
  src: url('../fonts/MetaPro-Light.eot');
  src: local('../fonts/MetaPro-Light'),
  url('../fonts/MetaPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MetaPro-Light.woff2') format('woff2'),
  url('../fonts/MetaPro-Light.woff') format('woff'),
  url('../fonts/MetaPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('../fonts/MetaPro-Thin.eot');
  src: local('../fonts/MetaPro-Thin'),
  url('../fonts/MetaPro-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MetaPro-Thin.woff2') format('woff2'),
  url('../fonts/MetaPro-Thin.woff') format('woff'),
  url('../fonts/MetaPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}