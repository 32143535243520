.left-right-cta-container{
  position: relative;
  z-index: 1;
  .left-container-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    @include small{
      display: none;
    }
  }
  .right-container-bg{
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: $color-blue;
    @include small{
      display: none;
    }
  }
  .page-constraint{
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 1fr 1fr;
    grid-gap: $base-spacing*4;
    @include small{
      grid-template-areas: "left"
                          "right";
      grid-template-columns: 1fr;
      grid-gap: $base-spacing*2;
    }
    .left-container,
    .right-container{
      padding: $base-spacing/2 0;
      .container-title{
        display: flex;
        align-items: center;
        align-content: center;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: $base-spacing/2;
        font-size: $font-size-sub-header;
        span{
          display: inline-block;
          margin-right: 10px;
          position: relative;
          top: 5px;
          svg,img{
            width: 56px;
            height: auto;
          }
        }
      }
      .container-content{
        font-weight: 500;
      }
      .container-link{
        margin-top: $base-spacing/2;
        a{
          color: $color-aqua;
          text-transform: uppercase;
          font-weight: 900;
          font-size: $font-size-sub-header;
          text-decoration: underline;
          letter-spacing: 1.5px;
        }
      }
    }

    .left-container{
      grid-area: left;
      color: #fff;
      .container-title{
        color: $color-blue;
        span{
          display: block;
        }
      }
      .container-content{
        color: $color-dark-grey;
      }
      .container-link{
        a{

        }
      }
    }

    .right-container{
      grid-area: right;
      color: #fff;
      @include small{
        background-color: $color-blue;
        margin-left: -$base-spacing;
        margin-right: -$base-spacing;
        margin-top: -$base-spacing;
        margin-bottom: -$base-spacing;
        padding: $base-spacing;
      }
      .container-title{
        span{
          display: block;
        }
        svg{
          stroke:#fff;
          circle{
            stroke:#fff;
          }
          path, polygon, line, ellipse{
            fill:#fff;
          }
        }
      }
      .container-content{

      }
      .container-link{
        a{

        }
      }
    }
  }
}